.head-card {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 24px;

  .welcome-row {
    margin-bottom: 90px;
  }

  .space-graphic-div {
    width: 110px;
    display: inline-block;
    background: rgba(0, 0, 0, 0.05);
    height: 224px;
    vertical-align: middle;
  }

  .content-div {
    max-width: calc(100% - 120px);
    display: inline-block;
    padding-left: 50px;
    vertical-align: middle;
  }

  .big-text {
    color: #000;
    font-family: Oxanium;
    font-size: 90px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
  }

  .normal-text {
    color: #000;
    font-family: Oxanium;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
  }

  .description-row {
    background: rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(75px);
    padding: 68px 38px 68px 160px;
  }

  .para-text {
    color: #000;
    font-family: Oxanium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    max-width: 520px;
  }

  .description-open-row {
    padding: 38px 38px 38px 160px;
  }

}

@media only screen and (max-width: 800px) {
  .head-card {
    .space-graphic-div {
      display: none;
    }

    .welcome-row {
      margin-bottom: 42px;
    }

    .description-row {
      padding: 38px 38px 38px 50px;
    }

    .big-text {
      font-size: 60px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .head-card {
    .space-graphic-div {
      display: none;
    }

    .welcome-row {
      margin-bottom: 42px;
    }

    .content-div {
      max-width: calc(100% - 0px);
      padding-left: 0px;
    }

    .description-row {
      padding: 16px 16px 16px 16px;
    }

    .big-text {
      font-size: 42px;
      line-height: 42px;
    }

    .normal-text {
      font-size: 16px;
    }

    .para-text {
      font-size: 12px;
      line-height: 136%;
    }
  }
}