.know-how{
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 24px;
  margin-top: 120px;

  .title-row {
    padding-left: 160px;
    margin-bottom: 42px;
  }

  .big-text {
    display: inline-block;
    color: #000;
    font-family: Oxanium;
    font-size: 90px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
    margin-right: 16px;
  }

  .small-text-box {
    display: inline-block;
  }

  .small-text {
    display: inline-block;
    color: #000;
    font-family: Oxanium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-right: 16px;
  }

  .smile-text {
    display: inline-block;
    color: #000;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
  }

  .know-how-content-row {
    /* text-align: center; */
  }

  .know-how-list-1 {
    text-align: left;
  }

  .know-how-list-2 {
    text-align: right;
  }

  .know-how-item {
    display: inline-block;
    padding: 0px 40px;
    margin: 8px 8px;
    color: #000;
    font-family: Oxanium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    background: rgba(0, 0, 0, 0.05);
    height:  100px;
    line-height: 100px;
    vertical-align: middle;
    text-wrap: nowrap;
  }

  .know-how-separator {
    display: inline-block;
    margin: 8px 8px;
    height:  100px;
    vertical-align: middle;
  }

  .know-how-separator img {
    height:  100px;
  }
}


@media only screen and (max-width: 800px) {
  .know-how {
    margin-top: 80px;

    .title-row {
      padding-left: 50px;
      margin-bottom: 28px;
    }

    .big-text {
      font-size: 60px;
    }

    .know-how-separator {
      height: 60px;
    }
    
    .know-how-separator img {
      height:  60px;
    }

    .know-how-item {
      height: 60px;
      line-height: 60px;
      font-size: 14px;
      padding: 0px 28px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .know-how {
    margin-top: 80px;

    .title-row {
      padding-left: 0px;
      margin-bottom: 28px;
    }

    .big-text {
      font-size: 42px;
      line-height: 42px;
    }

    .small-text {
      font-size: 13px;
      width: calc(100% - 60px);
    }

    .know-how-separator {
      height: 40px;
      margin: 4px 4px;
    }
    
    .know-how-separator img {
      height: 40px;
    }

    .know-how-item {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      padding: 0px 18px;
      margin: 4px 4px;
    }
  }
}