.header-card {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 24px;
  padding-top: 45px;
  height: 118px;

  .right-div {
    float: right;
  }

  .name-div {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }

  .name-text {
    color: #000;
    text-align: right;
    font-family: Oxanium;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -1.2px;
  }

  .desig-text {
    color: #000;
    text-align: right;
    font-family: Oxanium;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .profile-image-div {
    display: inline-block;
    vertical-align: middle;
    height: 110px;
    width: 110px;
  }

  .profile-image {
    height: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .header-card {
    .profile-image-div {
      height: 60px;
      width: 60px;
    }
  }
}