.join-me{
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 24px;
  margin-top: 120px;

  .title-row {
    padding-left: 160px;
  }

  .big-text {
    display: inline-block;
    color: #000;
    font-family: Oxanium;
    font-size: 90px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
    margin-right: 16px;
  }

  .small-text-box {
    display: inline-block;
  }

  .small-text {
    display: inline-block;
    color: #000;
    font-family: Oxanium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-right: 16px;
  }

  .detaied-row {

  }

  .note-col {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
  }

  .open-quote-mark {
    display: none;
    width: 160px;
    color: #000;
    font-family: Oxanium;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    vertical-align: top;
    text-align: right;
    padding-right: 20px;
  }

  .note-text {
    color: #000;
    font-family: Oxanium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: none;
    width: calc(100% - 180px);
  }

  .contact-col {
    width: 50%;
    display: inline-block;
    background-color: #333;
    padding: 70px 0px;
    vertical-align: middle;
    margin-top: 100px;
  }

  .contact-title {
    color: #FFF;
    font-family: Oxanium;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 48px;
    text-align: center;
  }

  .contact-email {
    color: #FFF;
    font-family: Oxanium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 20px;
    padding-left: 40px;
  }

  .contact-number {
    color: #FFF;
    font-family: Oxanium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding-left: 40px;
  }

  .email-icon {
    margin-right: 20px;
    vertical-align: middle;
  }

  .number-icon {
    margin-right: 20px;
    vertical-align: middle;
  }

}


@media only screen and (max-width: 800px) {
  .join-me {
    margin-top: 80px;

    .title-row {
      padding-left: 50px;
    }
    .big-text {
      font-size: 60px;
    }

    .contact-col {
      width: 100%;
      margin-top: 10px;
      padding: 38px 0px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .join-me {
    margin-top: 80px;

    .title-row {
      padding-left: 0px;
    }
    .big-text {
      font-size: 42px;
      line-height: 42px;
    }

    .small-text {
      font-size: 13px;
      margin-right: 0px;
    }

    .contact-col {
      width: 100%;
      margin-top: 10px;
      padding: 20px 0px;
    }

    .contact-title {
      font-size: 16px;
      margin-bottom: 30px;
    }

    .contact-email {
      font-size: 12px;
      padding-left: 20px;
      margin-bottom: 14px;
    }

    .email-icon {
      width: 22px;
    }

    .contact-number {
      font-size: 12px;
      padding-left: 20px;
      margin-bottom: 14px;
    }

    .number-icon {
      width: 22px;
    }
  }
}